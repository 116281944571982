import axios from 'axios';

interface AddProcedureSatisfactionRequest {
    surveyToken: string;
    satisfaction: {
        comment: string;
        rating: 1 | 2 | 3 | 4 | null;
    }
}

interface AddProcedureSatisfactionResponse {
    redirect: boolean;
}

export const AddProcedureSatisfaction = (data: AddProcedureSatisfactionRequest) => axios.post<AddProcedureSatisfactionResponse>('/Home/AddProcedureSatisfaction', data);
