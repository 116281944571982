import { Box, Button, Container, Grid, Input, Link, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import i18next from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegAngry, FaRegFrown, FaRegGrinBeam, FaRegSmile } from 'react-icons/fa';

import { AddProcedureSatisfaction } from '../../services/homePage.services';
import { useDialogContext } from '../../shared/contexts/DialogContext';
import { useLoaderContext } from '../../shared/contexts/LoaderContext';
import { InputProps, VdsButton, VdsTextField, VdsTooltip } from '../../vds';

//#region Constants & Types
const COMMENT_SIZE_LIMIT = Number(window['FrontendSettings']['TextArea']['CommentSizeLimit']);
const TS_PATH_REDIRECT = window['FrontendSettings']['Path']['TeamsystemBaseUrl'];
const HUBSPOT_USER_CONTENT_PDF = window['FrontendSettings']['Path']['HubspotUserContentPdf'];
const TRUSTPILOT_PATH_REDIRECT = window['FrontendSettings']['Path']['TrustpilotBaseUrl'];

interface FormModel {
    comment: string;
    rating: 1 | 2 | 3 | 4 | null;
}
//#endregion

//#region Styled components
const StyledPageContainer = styled(Container)(() => ({
    backgroundColor: "white",
    boxSizing: 'border-box',
    padding: '35px',
    marginTop: '20px'
}));
const StyledPageTitle = styled('span')(() => ({
    color: "rgb(21, 41, 53)",
    fontSize: "42px",
    fontWeight: 300,
    textAlign: "center",
    letterSpacing: "0.31px",
    lineHeight: "48px"
}));
const StyledPageComment = styled('span')(() => ({
    color: "rgb(90, 104, 114)",
    fontSize: "16px",
    fontFamily: "Roboto-Regular",
    fontWeight: "normal",
    textAlign: "center",
    letterSpacing: "0.44px",
    lineHeight: "24px"
}));
const StyledIconButton = styled(Button)(() => ({
    color: "rgb(193, 193, 196)",
    fontSize: "72px",
    fontWeight: 300,
    textAlign: "center",
    lineHeight: "24px",
    cursor: 'pointer',
    ':hover': {
        background: 'none',
        color: '#00c3ea'
    },
}));

//#endregion

//#region Utilities
export function checkFieldValidity<R>(
    data: any,
    errors: { [P in keyof R]: boolean | string },
    validators: Partial<{ [P in keyof R]: (value: R[keyof R]) => string | boolean }>
): {
    errors: { [P in keyof R]: boolean | string },
    errorCounter: number
} {

    let _errors = { ...errors };
    let errorCounter = 0;

    Object.keys(errors).forEach((field) => {
        const value = data[field];
        const _e = validators[field] && validators[field](value);
        if (_e) errorCounter++;
        _errors[field] = _e;
    });

    return {
        errors: _errors,
        errorCounter
    }
}
//#endregion

//#region Referenced components
const ContainerWithTooltip = React.forwardRef(function ContainerWithTooltip({ children, ...props }: any, ref: any) {
    return <Box {...props} ref={ref} >{children}</Box>
});

const CustomTextArea = React.forwardRef<HTMLElement, InputProps>(
    function TextMaskCustom(props, ref) {

        const { value } = props;
        const _l = (value as string)?.length || 0;

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Input
                    {...props}
                    multiline
                    inputRef={ref}
                    sx={{
                        width: '100%',
                        outline: 'none',
                        '::before': {
                            'all': 'unset'
                        },
                        '::after': {
                            'all': 'unset'
                        }

                    }}
                />
                <Box sx={{ fontSize: '14px', color: '#5A6872', textAlign: 'right' }}>
                    {_l}/{COMMENT_SIZE_LIMIT}
                </Box>
            </Box>
        );
    },
);
//#endregion

interface HomePageProps {
    token: string;
    procedureOid?: string | null;
}

export function HomePage({ token, procedureOid }: HomePageProps) {

    //#region Inizializzazione degli hooks
    const { t } = useTranslation();
    const setLoader = useLoaderContext();
    const setDialog = useDialogContext();
    //#endregion

    //#region Definizione di variabili e stati
    const [formData, setFormData] = React.useState<FormModel>({ comment: '', rating: null });
    const [formError, setFormError] = React.useState<{ [P in keyof Partial<FormModel>]: boolean | string }>({
        comment: '',
        rating: true
    });
    const formValidators = {
        comment: (value) => value.length <= COMMENT_SIZE_LIMIT ? false : t<string>("HomePage.invalidComment"),
        rating: (value) => value ? false : t<string>("HomePage.invalidRating")
    };
    //#endregion

    //#region Events handlers
    const handleChange = (key, value) => {

        setFormData({ ...formData, [key]: value });
        setFormError({
            ...formError,
            ...(formValidators[key] ? {
                [key]: formValidators[key] && formValidators[key](value)
            } : {})
        });
    };
    const submitRating = () => {

        const { errors, errorCounter } = checkFieldValidity(formData, formError, formValidators);

        if (errorCounter) setFormError(errors);
        else {

            setLoader(true);

            AddProcedureSatisfaction({
                surveyToken: token,
                satisfaction: formData
            })
                .then(({ data }) => {

                    const { redirect } = data;

                    setLoader(false);
                    if (redirect) window.location.replace(TRUSTPILOT_PATH_REDIRECT);
                    else {

                        setDialog({
                            open: true,
                            variant: 'error',
                            title: t<string>('HomePage.dialogSend_title'),
                            content: (
                                <React.Fragment>
                                    <span>{t<string>('HomePage.dialogSend_body')}</span>
                                    <br />
                                    <span>
                                        <b>{t<string>('HomePage.dialogSend_userContent1')}</b>, {t<string>('HomePage.dialogSend_userContent2')}
                                    </span>
                                    <br />
                                    <Link
                                        sx={{ cursor: 'pointer', textDecoration: 'none' }}
                                        onClick={() => {
                                            window.history.replaceState(null, null, window.location.origin);
                                            window.location.replace(HUBSPOT_USER_CONTENT_PDF);
                                        }}
                                    >
                                        {t<string>('Shared.clickInfoText')}
                                    </Link>
                                </React.Fragment>
                            ),
                            endCallback: (closeDialog) => {

                                closeDialog();
                                window.close();
                                window.location.replace(TS_PATH_REDIRECT);
                            }
                        });
                    }
                })
                .catch(() => {

                    setLoader(false);
                    setDialog({
                        open: true,
                        variant: 'error',
                        title: t<string>('HomePage.dialogSend_title'),
                        content: (
                            <React.Fragment>
                                <span>{t<string>('HomePage.dialogSend_body')}</span>
                                <br />
                                <span>
                                    <b>{t<string>('HomePage.dialogSend_userContent1')}</b>, {t<string>('HomePage.dialogSend_userContent2')}
                                </span>
                                <br />
                                <Link
                                    sx={{ cursor: 'pointer', textDecoration: 'none' }}
                                    onClick={() => {
                                        window.history.replaceState(null, null, window.location.origin);
                                        window.location.replace(HUBSPOT_USER_CONTENT_PDF);
                                    }}
                                >
                                    {t<string>('Shared.clickInfoText')}
                                </Link>
                            </React.Fragment>
                        ),
                        endCallback: (closeDialog) => {

                            closeDialog();
                            window.close();
                            window.location.replace(TS_PATH_REDIRECT);
                        }
                    });
                });
        }
    };
    //#endregion

    return (
        <Box sx={{ height: 'calc(100vh - 128px)', overflowY: 'auto' }}>
            <StyledPageContainer maxWidth="sm">
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                    <Grid sm={12} textAlign="center" item>
                        <StyledPageTitle>{`${t<string>("HomePage.title")}`}</StyledPageTitle>
                    </Grid>
                    <Grid sm={12} textAlign="center" item>
                        <StyledPageComment>
                            {`${t<string>("HomePage.comment")}`}<br />
                            {i18next.exists(`procedures:${procedureOid}`) ?
                                `${t<string>("HomePage.companySatisfactionComment", { ProcedureName: t(`procedures:${procedureOid}`) })}` :
                                `${t<string>("HomePage.defaultSatisfactionComment")}`}
                        </StyledPageComment>
                    </Grid>
                    <Grid xs={12} sm={12} md={3} lg={3} display="flex" justifyContent="center" sx={{ margin: '40px 0' }} alignItems="center" item>
                        <VdsTooltip title={`${t<string>("HomePage.veryDissatisfied")}`} placement="top" arrow>
                            <ContainerWithTooltip>
                                <StyledIconButton onClick={() => { handleChange('rating', 1) }} sx={{ ...(formData.rating === 1 && ({ color: '#0090d1' })) }}>
                                    <FaRegAngry />
                                </StyledIconButton>
                            </ContainerWithTooltip>
                        </VdsTooltip>
                    </Grid>
                    <Grid xs={12} sm={12} md={3} lg={3} display="flex" justifyContent="center" sx={{ margin: '40px 0' }} alignItems="center" item>
                        <VdsTooltip title={`${t<string>("HomePage.dissatisfied")}`} placement="top" arrow>
                            <ContainerWithTooltip>
                                <StyledIconButton onClick={() => { handleChange('rating', 2) }} sx={{ ...(formData.rating === 2 && ({ color: '#0090d1' })) }}>
                                    <FaRegFrown />
                                </StyledIconButton>
                            </ContainerWithTooltip>
                        </VdsTooltip>
                    </Grid>
                    <Grid xs={12} sm={12} md={3} lg={3} display="flex" justifyContent="center" sx={{ margin: '40px 0' }} alignItems="center" item>
                        <VdsTooltip title={`${t<string>("HomePage.satisfied")}`} placement="top" arrow>
                            <ContainerWithTooltip>
                                <StyledIconButton onClick={() => { handleChange('rating', 3) }} sx={{ ...(formData.rating === 3 && ({ color: '#0090d1' })) }}>
                                    <FaRegSmile />
                                </StyledIconButton>
                            </ContainerWithTooltip>
                        </VdsTooltip>
                    </Grid>
                    <Grid xs={12} sm={12} md={3} lg={3} display="flex" justifyContent="center" sx={{ margin: '40px 0' }} alignItems="center" item>
                        <VdsTooltip title={`${t<string>("HomePage.verySatisfied")}`} placement="top" arrow>
                            <ContainerWithTooltip>
                                <StyledIconButton onClick={() => { handleChange('rating', 4) }} sx={{ ...(formData.rating === 4 && ({ color: '#0090d1' })) }}>
                                    <FaRegGrinBeam />
                                </StyledIconButton>
                            </ContainerWithTooltip>
                        </VdsTooltip>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} hidden={!formData.rating} item>
                        <VdsTextField
                            label={t(formData.rating > 3 ? "HomePage.goodComment" : "HomePage.badComment")}
                            placeholder={t<string>("HomePage.textareaPH")}
                            value={formData.comment}
                            onChange={(ev) => { handleChange('comment', ev.target.value) }}
                            error={Boolean(formError.comment)}
                            errorText={String(formError.comment)}
                            inputComponent={CustomTextArea as any}
                            multiline
                            rows={8}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} item>
                        <Stack direction="row" spacing={2} justifyContent="center" textAlign="center">
                            <VdsButton
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    window.close();
                                    window.location.replace('https://www.teamsystem.com/');
                                }}
                            >{`${t<string>('Shared.close')}`}</VdsButton>
                            <VdsButton
                                variant="contained"
                                color="primary"
                                onClick={submitRating}
                                disabled={Boolean(formError.rating || formError.comment)}
                            >{`${t<string>('Shared.send')}`}</VdsButton>
                        </Stack>
                    </Grid>
                </Grid>
            </StyledPageContainer>
        </Box>
    );
};
