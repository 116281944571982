import * as React from 'react';
import loading3 from '../../assets/images/loading3.gif';

const ApplicationLoader: React.VFC<{ loader: boolean }> = ({ loader }) => loader && (
    <div
        style={{
            position: "relative",
            bottom: "100%",
            height: '100%',
            backgroundPosition: 'center',
            backgroundImage: `url(${loading3})`,
            backgroundRepeat: 'no-repeat',
            opacity: 0.5,
            backgroundColor: "#f2f5f8",
            zIndex: 888
        }}
    >
    </div>
)

export default ApplicationLoader;
