import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AlertContextProvider from './shared/contexts/AlertContext';
import DialogContextProvider from './shared/contexts/DialogContext';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <AlertContextProvider>
        <DialogContextProvider>
            <App />
        </DialogContextProvider>
    </AlertContextProvider>,
    rootElement);
