import * as React from 'react';
import ApplicationLoader from '../components/ApplicationLoader';

const LoaderContext = React.createContext(null);

type UserLoaderContextType = () => React.Dispatch<React.SetStateAction<boolean>>;

export const useLoaderContext: UserLoaderContextType = () => React.useContext(LoaderContext);

const LoaderContextProvider = ({ children }) => {

    const [loader, setLoader] = React.useState<boolean>(false);

    return (
        <LoaderContext.Provider value={setLoader}>
            {children}
            <ApplicationLoader loader={loader} />
        </LoaderContext.Provider>
    );
};

export default LoaderContextProvider;
