import React from 'react';
import './custom.css';
import { ApplicationLayout } from './shared/components';
import i18n from './shared/utils/i18n';

const App = () => {

    // Imposta la lingua su quella del browser
    React.useEffect(() => {

        i18n.changeLanguage(navigator.language);
    }, []);

    return (
        <ApplicationLayout />
    );
}

export default App;
