/*eslint unicode-bom: "off"*/
import { Close } from '@mui/icons-material';
import { AlertProps, ClickAwayListener, IconButton, Snackbar, styled } from '@mui/material';
import * as React from 'react';
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { VdsAlert } from '../../vds';

const UpdateAlertContext = createContext(null);

export const useAlertContext: () => Dispatch<SetStateAction<{
    severity: AlertProps['color'],
    message: string,
    open: boolean
}>> = () => useContext(UpdateAlertContext);

const StyledSnackbar = styled(Snackbar)(() => ({
    minWidth: 350
}))

const AlertContextProvider = ({ children }) => {

    const [snackbar, setSnackbar] = useState<{
        severity: AlertProps['color'],
        message: string,
        open: boolean,
    }>({
        severity: 'warning',
        message: '',
        open: false
    });

    return (
        <UpdateAlertContext.Provider value={setSnackbar}>
            {children}
            {snackbar.severity === 'error' ? (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => {
                        setSnackbar({ ...snackbar, open: false });
                    }}
                >
                    <StyledSnackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={snackbar.open}
                        onClose={(event, reason) => {
                            setSnackbar({ ...snackbar, open: false });
                        }}
                    >
                        <VdsAlert
                            severity="error"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '460px',
                                '.MuiAlert-action': {
                                    padding: 0,
                                },
                                '.MuiAlert-icon': {
                                    color: '#D82829'
                                }
                            }}
                            action={(
                                <IconButton
                                    color="secondary"
                                    sx={{
                                        color: '#D82829'
                                    }}
                                    size="small"
                                    onClick={() => {
                                        setSnackbar({ ...snackbar, open: false });
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            )}
                        >
                            {snackbar.message}
                        </VdsAlert>
                    </StyledSnackbar>
                </ClickAwayListener>
            ) : (
                <StyledSnackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={snackbar.open}
                    autoHideDuration={3500}
                    onClose={(event, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        setSnackbar({ ...snackbar, open: false });
                    }}
                >
                    <VdsAlert
                        severity={snackbar.severity}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '460px',
                        }}
                    >
                        {snackbar.message}
                    </VdsAlert>
                </StyledSnackbar>
            )}
        </UpdateAlertContext.Provider>
    );
};

export default AlertContextProvider;
