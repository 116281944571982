/*eslint unicode-bom: "off"*/
import { DialogContentText } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { createContext, Dispatch, ReactElement, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VdsButton, VdsDialog, VdsDialogProps } from "../../vds";

const UpdateDialogContext = createContext(null);

type DialogProps = {
    variant: VdsDialogProps['variant'],
    title: string | ReactElement,
    content: string | ReactElement,
    open: boolean,
    endCallback: (close: () => void) => void
};

export const useDialogContext: () => Dispatch<SetStateAction<DialogProps>> = () => useContext(UpdateDialogContext);

const DialogContextProvider = ({ children }) => {

    const { t } = useTranslation();

    const [dialog, setDialog] = useState<DialogProps>({
        variant: 'default',
        title: '',
        content: '',
        open: false,
        endCallback: () => { }
    });

    const closeDialog = () => setDialog({
        ...dialog,
        open: false,
    });

    // Gestisci la callback di chiusura dopo 5 secondi
    React.useEffect(() => {

        if (dialog.open) {

            const closingDelay = Number(window['FrontendSettings']['Modal']['ClosingDelay']);

            setTimeout(() => {

                dialog.endCallback(closeDialog);

            }, closingDelay);
        }
    }, [dialog.open]);

    return (
        <UpdateDialogContext.Provider value={setDialog}>
            <React.Fragment>
                {children}
                <VdsDialog
                    open={dialog.open}
                    fullWidth={true}
                    maxWidth="xs"
                >
                    <DialogTitle>{dialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dialog.content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <VdsButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                dialog.endCallback(closeDialog);
                            }}
                        >
                            <React.Fragment>
                                {`${t<string>('Shared.end') }`}
                            </React.Fragment>
                        </VdsButton>
                    </DialogActions>
                </VdsDialog>
            </React.Fragment>
        </UpdateDialogContext.Provider>
    );
};

export default DialogContextProvider;
