import { Grid, useMediaQuery, useTheme, styled } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import errorPage from '../../assets/images/errorPage.jpg';
import logo from '../../assets/images/logo_TS.png';
import { VdsButton } from '../../vds';

const ErrorPage = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up(970));

    return (
        <Grid sx={{ height: '100vh', overflow: 'auto' }} container>
            <Grid hidden={!matches} sm={5} sx={{ backgroundColor: '#e4eaf0', display: 'flex', alignItems: 'center', justifyContent: 'center' }} item>
                <img src={errorPage} />
            </Grid>
            <Grid sm={matches ? 7 : 12} sx={{
                padding: '34px 34px 34px 68px',
                background: "linear-gradient(-225deg, rgb(250, 250, 254) 0%, rgb(249, 249, 254) 100%)"
            }} item>
                <Grid sm={12} textAlign="right" item>
                    <img src={logo} style={{ marginBottom: '70px' }} />
                </Grid>
                <Grid sm={12} item>
                    <span
                        style={{
                            color: "rgb(0, 80, 117)",
                            fontSize: "40px",
                            fontFamily: "Cairo-SemiBold",
                            fontWeight: 600,
                            letterSpacing: "0px",
                            lineHeight: "54px"
                        }}
                    >
                        OOPS!
                    </span>
                </Grid>
                <Grid sm={12} item>
                    <span
                        style={{
                            color: "rgb(0, 80, 117)",
                            fontSize: "40px",
                            fontFamily: "Cairo-SemiBold",
                            fontWeight: 600,
                            letterSpacing: "0px",
                            lineHeight: "54px"
                        }}
                    >
                        {t<string>("ErrorPage.errorMessage")}
                    </span>
                </Grid>

                <Grid sm={12} item>
                    <p
                        style={{
                            color: "rgb(21, 41, 53)",
                            fontSize: "20px",
                            fontFamily: "Cairo-Regular",
                            fontWeight: "normal",
                            letterSpacing: "0.15px",
                            lineHeight: "24px",
                            marginTop: '24px'
                        }}
                    >
                        {t<string>('ErrorPage.loadingProblemMessage')}
                    </p>
                </Grid>

                <Grid sm={12} item>
                    <p
                        style={{
                            color: "rgb(90, 104, 114)",
                            fontSize: "18px",
                            fontFamily: "Roboto-Regular",
                            fontWeight: "normal",
                            letterSpacing: "0px",
                            marginTop: '24px'
                        }}
                    >
                        {t<string>('ErrorPage.errorCode404', { Date: moment(new Date()).format('GG/MM/YYYY - HH:mm:ss') })}
                    </p>
                </Grid>

                <Grid sm={12} item>
                    <p
                        style={{
                            color: "rgb(0, 0, 0)",
                            fontSize: "24px",
                            fontFamily: "Roboto-Regular",
                            fontWeight: "normal",
                            letterSpacing: "0px",
                            marginTop: '98px'
                        }}
                    >
                        {t<string>('ErrorPage.reloadPage')} <a target="_blank" style={{ textDecoration: 'none' }} href="https://mysupport.teamsystem.com">My Support</a>
                    </p>
                </Grid>
                <Grid sm={12} item>
                    <VdsButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            window.close();
                            window.location.replace('https://www.teamsystem.com/');
                        }}
                    >
                        {`${t<string>('Shared.close')}`}
                    </VdsButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ErrorPage;
