import { Box, Divider, Grid } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo_TS.png';
import { HomePage } from '../../features';
import { VdsPageTitle } from '../../vds/VdsPageTitle/VdsPageTitle';
import LoaderContextProvider from '../contexts/LoaderContext';
import ErrorPage from './ErrorPage';

const ApplicationLayout = () => {

    const { t } = useTranslation();

    //#region Definizione di variabili e stati
    const [token, setToken] = React.useState<string>('');
    const [procedureOid, setProcedureOid] = React.useState<string>('');
    const [error, setError] = React.useState(false);
    //#endregion

    //#region useEffect
    React.useEffect(() => {

        const pathname = window.location.pathname;

        if (pathname.includes('swagger')) return;

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('surveyToken');
        const procedureOid = urlParams.get('procedureOid');

        const tokenIsValid = token && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(token);

        setProcedureOid(procedureOid);

        if (tokenIsValid) setToken(token);
        else setError(true);
    }, []);
    //#endregion

    if (error) {

        return <ErrorPage />;
    }

    return (
        <Box sx={{ height: '100%' }}>
            <Grid 
                spacing={2} 
                container 
                sx={{
                    padding: '16px',
                    backgroundColor: 'white'
                }}
            >
                <Grid lg={2} md={12} sm={12} xs={12} display="flex" justifyContent="center" alignItems="center" item>
                    <img src={logo} height="32p" />
                </Grid>
                <Grid lg={8} md={12} sm={12} xs={12} display="flex" justifyContent="center" alignItems="center" item>
                    <VdsPageTitle title={t<string>("Shared.applicationTitle")} />
                </Grid>
                <Grid lg={2} md={0} sm={0} xs={0} item></Grid>
            </Grid>
            <Divider
                sx={{
                    background: "rgb(0, 144, 209)",
                    borderRadius: "0px",
                    height: "2px",
                }}
            />
            <LoaderContextProvider>
                <HomePage token={token} procedureOid={procedureOid} />
            </LoaderContextProvider>
        </Box>
    );
}

export default ApplicationLayout;
