import { styled } from '@mui/styles';
import * as React from 'react';

type Content = any;

export type PageTitleProps = {
    title: string;
    textAlign?: 'center' | 'left' | 'right';
    content?: Content;
    historyBackEvent?: React.MouseEventHandler<HTMLElement>;
}

const StyledPageTitle = styled('div')({
    background: "rgb(255, 255, 255)",
    borderRadius: "0px"
});

const StyledTitleContainer = styled('div')(() => ({
    color: "rgb(0, 80, 117)",
    fontSize: "20px",
    textAlign: "center",
    fontFamily: "Cairo-Regular",
    fontWeight: "normal",
    lineHeight: "24px",
    padding: "2px 0",
    letterSpacing: "0.15px",
    backgroundColor: "white"
}));

export const VdsPageTitle: React.VFC<PageTitleProps> = (props: PageTitleProps) => {

    const { title } = props;

    return (
        <StyledPageTitle>
            <StyledTitleContainer>
                {title.toUpperCase()}
            </StyledTitleContainer>
        </StyledPageTitle>
    );
}
